import { HttpClient } from '@angular/common/http';
import { AppState } from 'app/store/app.state';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { responceData } from 'app/models/responce.model';
import {
  CityData,
  CompanyData,
  CompanyDraftData,
  CompanyDraftResponceData,
  CompanyResponceData,
  CountryData,
  MacroregionData,
  RegionData,
  companyOption,
  templateResponseData,
} from '../model/SharedModel';
import { setConfirmMailTimerAction } from '../shared.actions';
import { environment } from '@environment/environment';
import { setQueryParams } from '@shared/helpers/set-query-headers';

interface CountriesResponceData extends Omit<responceData, 'data'> {
  data: CountryData[];
}
interface RegionsResponceData extends Omit<responceData, 'data'> {
  data: {
    id: string;
    name: string;
    inCountry: string;
  }[];
}
interface MacroRegionsResponceData extends Omit<responceData, 'data'> {
  data: {
    id: string;
    name: string;
    continent: string;
  }[];
}
interface CitiesResponceData extends Omit<responceData, 'data'> {
  data: {
    id: string;
    name: string;
    inRegion: string;
  }[];
}
interface sendMailVereficationCodeResponceData
  extends Omit<responceData, 'data'> {
  data: string;
}

interface addCompanyResponceData extends Omit<responceData, 'data'> {
  data: CompanyResponceData[];
}

interface addCompanyDraftResponceData extends Omit<responceData, 'data'> {
  data: CompanyDraftResponceData;
}

interface getCompanyOptionsResponce extends Omit<responceData, 'data'> {
  data: companyOption[];
}

interface sendFileResponce extends Omit<responceData, 'data'> {
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  BASE_URL = `${environment.API_BASE_URL}`;
  constructor(private http: HttpClient, private store: Store<AppState>) {}

  /*LOCATIONS*/
  getAllCountries(): Observable<CountriesResponceData> {
    return this.http.get<CountriesResponceData>(`${this.BASE_URL}/countries`);
  }

  getAllRegions(): Observable<RegionsResponceData> {
    return this.http.get<RegionsResponceData>(`${this.BASE_URL}/regions`);
  }

  getAllMacroRegions(): Observable<MacroRegionsResponceData> {
    return this.http.get<MacroRegionsResponceData>(
      `${this.BASE_URL}/macro-regions`
    );
  }

  getAllCities(): Observable<CitiesResponceData> {
    return this.http.get<CitiesResponceData>(`${this.BASE_URL}/cities`);
  }

  //cities by country
  getCountriesByMacro(id: string): Observable<CountriesResponceData> {
    return this.http.get<CountriesResponceData>(
      `${this.BASE_URL}/countries/macro-region/${id}`
    );
  }

  //cities by country
  getCitiesByCountry(id: string): Observable<CitiesResponceData> {
    return this.http.get<CitiesResponceData>(
      `${this.BASE_URL}/cities/country/${id}`
    );
  }
  /*LOCATIONS*/

  sendMailVereficationCode(): Observable<any> {
    return this.http.post(`${this.BASE_URL}/profile/mail-verify`, null);
  }
  sendMailConfirm(
    code: string
  ): Observable<sendMailVereficationCodeResponceData> {
    return this.http.post<sendMailVereficationCodeResponceData>(
      `${this.BASE_URL}/profile/mail-confirm`,
      {
        verificationCode: code,
      }
    );
  }

  /*COMPANY*/

  //company add
  addCompany(data: CompanyData): Observable<addCompanyResponceData> {
    return this.http.post<addCompanyResponceData>(
      `${this.BASE_URL}/companies/add`,
      {
        ...data,
      }
    );
  }

  getCompanyById(id: string): Observable<any> {
    return this.http.get<any>(`${this.BASE_URL}/companies/${id}`);
  }

  createTemplate(data: any): Observable<any> {
    return this.http.post<any>(`${this.BASE_URL}/companies/add`, data);
  }

  updateTemplate(data: any, id: string): Observable<any> {
    return this.http.put<any>(`${this.BASE_URL}/companies/update/${id}`, data);
  }

  //company draft add
  addCompanyDraft(
    data: CompanyDraftData
  ): Observable<addCompanyDraftResponceData> {
    return this.http.post<addCompanyDraftResponceData>(
      `${this.BASE_URL}/drafts/add`,
      {
        ...data,
      }
    );
  }

  //get options
  getCompanyOptions(): Observable<getCompanyOptionsResponce> {
    return this.http.get<getCompanyOptionsResponce>(`${this.BASE_URL}/options`);
  }

  //get option by id
  getCompanyOptionById(id: string): Observable<getCompanyOptionsResponce> {
    return this.http.get<getCompanyOptionsResponce>(
      `${this.BASE_URL}/options/${id}`
    );
  }
  /*COMPANY*/

  codeTimer = (): void => {
    let time: number;
    this.store
      .select((state) => state.shared.time)
      .subscribe((timer) => {
        time = timer;
      });

    const timePut = setTimeout(() => {
      time -= 1;
      console.log(time);
      if (time < 0) {
        this.store.dispatch(
          setConfirmMailTimerAction({ timeStart: true, time: time })
        );
        clearTimeout(timePut);
        return;
      }
      this.store.dispatch(
        setConfirmMailTimerAction({ timeStart: true, time: time })
      );
      this.codeTimer();
    }, 1000);
  };

  sendFile = ({ data }: any): Observable<any> => {
    return this.http.post(`${this.BASE_URL}/files/add`, data);
  };

  getOrderList = (): Observable<any> => {   //with hardcode sort
    return this.http.get(`${this.BASE_URL}/companies?sortKey=createdAt&sortOrder=desc`);
  };

  getOrderById = (id: string): Observable<any> => {
    return this.http.get(`${this.BASE_URL}/companies/${id}`);
  };
  delteOrderById = (id: string): Observable<any> => {
    return this.http.delete<any>(`${this.BASE_URL}/companies/delete/${id}`);
  };

  sendCardToken = ({ data }: any): Observable<any> => {
    return this.http.post<any>(
      `${this.BASE_URL}/profile/payment-methods/add`,
      data
    );
  };

  getPaymentMethods = (): Observable<any> => {
    return this.http.get<any>(`${this.BASE_URL}/profile/payment-methods`);
  };
  deletePaymentMethod = (id: string): Observable<any> => {
    return this.http.delete<any>(
      `${this.BASE_URL}/profile/payment-methods/delete/${id}`
    );
  };

  getServices = ({ data }: any): Observable<any> => {
    return this.http.get<any>(
      `${this.BASE_URL}/services`,
      setQueryParams(data)
    );
  };

  getServiceCategories = (): Observable<any> => {
    return this.http.get<any>(`${this.BASE_URL}/service-categories`);
  };

  getServicesByFormId = ({ data }: any): Observable<any> => {
    return this.http.get<any>(`${this.BASE_URL}/services/form/${data.formId}`);
  };

  addPayment = ({ data }: any): Observable<any> => {
    return this.http.post<any>(`${this.BASE_URL}/payments/add`, data);
  };

  updatePaymentMethods = (data: any, id: string): Observable<any> => {
    return this.http.put<any>(
      `${this.BASE_URL}/profile/payment-methods/update/${id}`,
      data
    );
  };

  uploadFile = (data: any): Observable<any> => {
    return this.http.post<any>(`${this.BASE_URL}/files/add`, data);
  };

  getOrders = ({ data }: any): Observable<any> => {
    return this.http.get<any>(`${this.BASE_URL}/orders`, setQueryParams(data));
  };

  getOrdersById = (id: string): Observable<any> => {
    return this.http.get<any>(`${this.BASE_URL}/orders/${id}`);
  };

  getMessages = (data: any): Observable<any> => {
    let params = setQueryParams(data);
    return this.http.get<any>(`${this.BASE_URL}/messages`, {observe: 'response', params: params!.params});
  };
}
